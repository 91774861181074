import React, { useState, useEffect } from "react";
import styled from "styled-components";
import catflixgif from "../images/catflixgif.gif";
// import catflixpic2 from "../images/Catflixpic2.png";
// import catflixpic3 from "../images/Catflixpic3.png";
// import catflixpic4 from "../images/Catflixpic4.png";

const Catflix = () => {
  const onClick = () => {
    window.open("https://catflix.netlify.app/", "_blank");
  };

  const visitGithub = () => {
    window.open("https://github.com/Tobiasltd/Catflix", "_blank");
  };
  return (
    <div>
      <Background>
        <Container id="catflix">
          <p>Video platform</p>
          <Title>Catflix</Title>
          <Intro>
            <Column>
              <Subtitle>Overview</Subtitle>
              <p>
                Upon finding out Netflix was built on the React framework, I
                tried to recreate the streaming platform. In order to avoid
                copyright issues, I made Catflix. It has been modeled after
                Netflix' September major layout update.
                <br />
                <br />
                The website is fully built on React and is serverless. It uses
                Firebase for authentication and to retrieve series and films
                data for app wide context.
                <br />
                <br />
                Catflix features a login page, films and series page and a page
                where the users personal watchlist is saved. It also features a
                search bar which matches the search against title, genre and the
                description of the programme. Users can like and dislike
                programmes and every programme comes with five 'more like this'
                options based on genre.
                <br />
                <br />
                The website has been filled up with cat pictures and videos I
                had laying around of my two kittens.
              </p>
              <Subtitle className="pointer" onClick={visitGithub}>
                Github <i className="fab fa-github"></i>
              </Subtitle>
              <p className="pointer" onClick={visitGithub}>
                Visit Github
              </p>
            </Column>
            <Column>
              <Grid>
                <Tech>
                  <Subtitle>Technologies</Subtitle>
                  <p>
                    <ul>
                      <li>React</li>
                      <li>Firebase</li>
                    </ul>
                  </p>
                </Tech>
                <div>
                  <Subtitle>Functionality</Subtitle>
                  <p>
                    <ul>
                      <li>Authentication</li>
                      <li>Films & series sections</li>
                      <li>My list with saved programmes</li>
                      <li>Like & dislikes</li>
                      <li>More like this suggestions</li>
                      <li>Search bar</li>
                    </ul>
                  </p>
                </div>
              </Grid>
              <PortfolioItem>
                <img src={catflixgif} alt="" />
                <VisitWebsite onClick={onClick}>Visit Website</VisitWebsite>
              </PortfolioItem>
            </Column>
          </Intro>
        </Container>
      </Background>
    </div>
  );
};

const Tech = styled.div`
  padding-left: 5rem;
  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.div`
  font-size: 3rem;
  letter-spacing: 2px;
  color: #fff;
`;

const VisitWebsite = styled.div`
  display: inline-block;
  border: 5px solid white;
  color: #fff;
  padding: 0.8rem 2.6rem;
  font-size: 1.3rem;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
  a {
    color: #fff;
  }
`;

const PortfolioItem = styled.div`
  text-align: center;
  color: #000;
  padding: 4rem;
  i {
    font-size: 5rem;
  }
  @media (max-width: 701px) {
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-bottom: 1px solid black;
  }
`;

const Subtitle = styled.div`
  font-weight: 1000;
  color: #fff;
  margin-top: 1rem;
`;

const Column = styled.div`
  color: #b8b8b8;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  span {
    color: #fff;
  }
`;

const Intro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media (max-width: 701px) {
    grid-template-columns: 1fr;
  }
`;

const Background = styled.div`
  background: #2a2a2a;
  height: 100%;
  width: 100vw;
`;

const Container = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 0.7rem 2rem;
  color: #b8b8b8;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
`;

export default Catflix;
